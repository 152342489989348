import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Автомийка "Буде Чисто"
			</title>
			<meta name={"description"} content={"Кожен матч - шедевр!"} />
			<meta property={"og:title"} content={"Головна | Тенісний корт Global Minddex"} />
			<meta property={"og:description"} content={"Кожен матч - шедевр!"} />
			<meta property={"og:image"} content={"https://globalminddex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://globalminddex.com/img/central-park.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://globalminddex.com/img/central-park.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://globalminddex.com/img/central-park.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://globalminddex.com/img/central-park.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://globalminddex.com/img/central-park.png"} />
			<meta name={"msapplication-TileImage"} content={"https://globalminddex.com/img/central-park.png"} />
			<meta name={"msapplication-TileColor"} content={"https://globalminddex.com/img/central-park.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="50px 0 50px 0" sm-padding="80px 0 50px 0" quarkly-title="Hero-11" sm-text-align="center">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					padding="80px 0px 80px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					sm-padding="0 0px 50px 0px"
				>
					<Text margin="0px 0px 30px 0px" font="normal 700 42px/1.2 --fontFamily-sans" sm-font="normal 700 36px/1.2 --fontFamily-sans" color="--darkL2">
					Тенісний корт Global&nbsp;Minddex
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					Відкрийте для себе радість гри на справжніх глиняних кортах у Global Minddex Tennis Haven. Наш об'єкт присвячений створенню тенісного середовища найвищого рівня, яке задовольняє як ентузіастів, так і гравців, що змагаються між собою. Насолоджуйтесь грою в оточенні чистого шарму глини - улюбленого покриття багатьох легенд тенісу.

					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						flex-direction="column"
					>
						<Button
							padding="15px 38px 15px 38px"
							background="--color-primary"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							letter-spacing="1px"
							margin="0px 0px 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-primary"
							hover-color="--darkL1"
							hover-background="rgba(215, 26, 89, 0.03)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							ЗНАЙТИ НАС
						</Button>
					</Box>
				</Box>
				<Image
					src="https://globalminddex.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					sm-height="500px"
					height="100%"
					lg-width="100%"
					lg-height="auto"
				/>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				Наші переваги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 30px 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
					Оптимальні умови гри: Наші глиняні корти зменшують жорсткість ударів, полегшуючи навантаження на суглоби і дозволяючи грати довше без дискомфорту, який зазвичай відчувається на більш твердих покриттях.

					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#35363a">
					Призначені для всіх рівнів майстерності: Незалежно від того, новачок ви чи професіонал, наші корти створені для того, щоб забезпечити ідеальне середовище для вдосконалення вашої гри.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a" max-width="450px">
						
Зосередьтеся на спільноті: У Global Minddex ми розвиваємо спільноту гравців, які поділяють пристрасть до тенісу, забезпечуючи чудове місце для зустрічей і змагань з іншими любителями тенісу.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
			Готові керувати глиняним покриттям?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Відкрийте для себе Global Minddex!
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Global Minddex - це не просто гра в теніс, це можливість відчути гру в її чистому вигляді на добре доглянутих глиняних кортах. Тут кожна подача і кожен удар живе своїм власним життям, дозволяючи вам грати довше, з більшою стратегією і меншим навантаженням на тіло. Наш центр зосереджений на вдосконаленні ваших тенісних навичок у доброзичливій та професійній атмосфері.
					</Text>
				</Box>
				<Image src="https://globalminddex.com/img/2.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});